import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCar, faWalking, faBicycle } from '@fortawesome/free-solid-svg-icons';

import { updateMapControls } from '../../actions/mapControls';
import { fetchReachability, fetchStatsSocioDemo } from '../../services/api';

const BE_TOTAL_POPULATION = "11 534 392";
const BE_TOTAL_AVG_PURCHASING_POWER = "21 548";

const COLORS = [
	'red',
	'green',
	'blue',
	'purple',
	'brown'
];

const Reachability = ({ property }) => {
	const [travelMode, setTravelMode] = useState('driving');
	const [time, setTime] = useState(10);
	const count = useSelector(state => state.mapControls.reachability.count);
	const reachabilityProperties = useSelector(state => state.mapControls.reachability.properties);
	const dispatch = useDispatch();
	const reachability = reachabilityProperties.find(reachabilityProperty => property.id === reachabilityProperty.data.id);

	const handleChangeTravelMode = (e) => {
		setTravelMode(e.target.value);
	};

	const handleChangeTime = (e) => {
		setTime(e.target.value);
	};

	const handleClickAdd = async () => {
		if (!time || time <= 0 || time > 60) {
			return;
		}

		let response = await fetchReachability({ locations: [[property.lng, property.lat]], range: [time*60], travelMode: travelMode });
		let stats;
		const polygon = response?.features[0]?.geometry;
		if (polygon) {
			stats = await fetchStatsSocioDemo({ polygon: response.features[0].geometry });
			if (stats.success) {
				response.features[0].properties = { ...response.features[0].properties, time, stats: { ...stats.data } };
			}
		}

		const foundIndex = reachabilityProperties.findIndex(item => item.id === property.id);
		let result = [...reachabilityProperties];
		let updatedCount = count;

		if (foundIndex >= 0) {
			result[foundIndex] = {
				...reachabilityProperties[foundIndex],
				features: [...reachabilityProperties[foundIndex].features, response.features[0]]
			};
		}
		else {
			updatedCount++;
			result.push({ id: property.id, data: property, color: COLORS[count % COLORS.length], features: [response.features[0]] });
		}

		dispatch(updateMapControls({
			reachability: {
				count: updatedCount,
				properties: result,
			}
		}));
	};

	const handleClickReset = () => {
		const updatedReachabilityProperties = reachabilityProperties.filter(reachabilityProperty => property.id !== reachabilityProperty.data.id) || [];

		dispatch(updateMapControls({
			reachability: {
				count: updatedReachabilityProperties.length === 0 ? 0 : count,
				properties: updatedReachabilityProperties
			}
		}));
	};

	if (!property) {
		return null;
	}

	return (
		<div className="mb-4">
			<div className="row d-flex align-items-center mb-2">
				<div className="col-md-12">
					<h5 className="mb-0">Drive times</h5>
				</div>
			</div>

			<div className="row mb-2">
				<div className="col-md-12">
					<div className="btn-group btn-group-toggle" data-toggle="buttons">
						<label className={`btn btn-outline-light ${travelMode === 'driving' ? 'active' : ''}`}>
							<input type="radio" name="reachabilityTravelMode" value="driving" checked={travelMode === 'driving'} onChange={handleChangeTravelMode} />
							<FontAwesomeIcon icon={faCar} className="m-0" />
						</label>
						<label className={`btn btn-outline-light ${travelMode === 'cycling' ? 'active' : ''}`}>
							<input type="radio" name="reachabilityTravelMode" value="cycling" checked={travelMode === 'cycling'} onChange={handleChangeTravelMode} />
							<FontAwesomeIcon icon={faBicycle} className="m-0" />
						</label>
						<label className={`btn btn-outline-light ${travelMode === 'walking' ? 'active' : ''}`}>
							<input type="radio" name="reachabilityTravelMode" value="walking" checked={travelMode === 'walking'} onChange={handleChangeTravelMode} />
							<FontAwesomeIcon icon={faWalking} className="m-0" />
						</label>
					</div>
				</div>
			</div>
			<div className="row d-flex align-items-center">
				<div className="col-md-7">
					<div className="input-group">
						<input
							type="number"
							id="reachability-time"
							className="form-control"
							value={time}
							min={1}
							max={60}
							step={1}
							onChange={handleChangeTime}
						/>
						<div className="input-group-append">
							<span className="input-group-text">min</span>
						</div>
					</div>
				</div>
				<div className="col-md-5 d-flex">
					<button className="btn btn-primary mr-1 btn-sm" onClick={handleClickAdd}>Add</button>
					{reachabilityProperties.some(reachabilityProperty => property.id === reachabilityProperty.data.id) && (
						<button className="btn btn-light btn-sm" onClick={handleClickReset}>Reset</button>
					)}
				</div>
			</div>
			{reachability && (
				<div className="row mt-3">
					<div className="col-md-12">
						<table>
							<thead>
								<tr>
									<th>Time</th>
									<th>Population</th>
									<th>Average purchasing power (€/pp)</th>
								</tr>
							</thead>
							<tbody>
								{reachability.features.map((feature, index) => (
									<tr key={`drive_time_${index}`}>
										<td>{feature.properties.time}min</td>
										<td>{new Intl.NumberFormat('en-US').format(feature.properties.stats.inhabitants)}</td>
										<td>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }).format(feature.properties.stats.average_purchasing_power)}</td>
									</tr>
								))}
								<tr className="tr-border-top">
									<td>Belgium</td>
									<td>{BE_TOTAL_POPULATION}</td>
									<td>€{BE_TOTAL_AVG_PURCHASING_POWER}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			)}
		</div>
	);
};

export default Reachability;